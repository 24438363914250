<template>
  <v-container>
    <v-row >
      <v-col cols="12">
        <v-img
          :src="require('../assets/work-98936.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
      <v-col cols="12" justify="center" align="center">
        <h1 >{{msg}}</h1>
        <h3>El sitio aún se encuentra en construcción pero puedes revisar nuestros datos de contacto</h3>
      </v-col>
      <v-card class="mx-auto my-2" min-width="500" v-for="contacto in contactos" :key="contacto.phone">
          <v-card-title>{{contacto.title}}</v-card-title>
          
          <v-card-text class="text--primary">
            <div>Correo electrónico: <a :href="'mailto:'+contacto.email">{{ contacto.email }}</a></div>
          </v-card-text>
        </v-card>
    </v-row>
  </v-container>
</template>

<script>
  var domain ="@equipamas.com"
  export default {
    name: 'inConstruction',
    props: {
      msg: String
    },
    data: () => ({
      contactos: [
        {
          title: 'Contabilidad',
          email: 'contabilidad'+domain
        },
        {
          title: 'Ventas',
          email: 'ventas'+domain
        },
        {
          title: 'Soporte',
          email: 'soporte'+domain
        },
        {
          title: 'Ingenieria',
          email: 'ingenieria'+domain
        },
        {
          title: 'GerenteS',
          email: 'darinka'+domain
        },
      ]
    }),
  }
</script>

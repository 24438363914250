<template>
  <div class="home">
    <InConstruction msg="Bienvenido a Equipamás"/>
  </div>
</template>

<script>
// @ is an alias to /src
import InConstruction from '@/components/inContruction'

export default {
  name: 'Home',
  components: {
    InConstruction
  }
}
</script>
